import Head from 'next/head';
export default function MetaSeo(props) {
    let { url, type, title, description, image, domain, card, keywords } = props;
    if (!url) {
        url = 'https://eyefire.vn';
    }
    if (!type) {
        type = 'website';
    }
    if (!title) {
        title = 'EYEFIRE - Công ty công nghệ đa lĩnh vực hàng đầu Việt Nam';
    }
    if (!description) {
        description = 'EYEFIRE - Tiên phong trong nghiên cứu, phát triển các giải pháp dựa trên công nghệ trí tuệ nhân tạo như nhận diện khuôn mặt, đối tượng, hành vi trong video, hình ảnh, mang lại giá trị gia tăng và đáp ứng nhu cầu khách hàng.';
    }
    if (!image) {
        image = 'https://eyefire.vn/assets/images/eyefire_logo.png';
    }
    if (!domain) {
        domain = 'eyefire.vn';
    }
    if (!card) {
        card = 'summary_large_image';
    }
    if (!keywords) {
        keywords = 'Leading IT company in Vietnam , Outsourcing IT service in Vietnam, Offshore, Outsourcing, Artificial Intelligence, Blockchain, AR/VR, Livestreaming';
    }

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name='description' content={description} />
                <meta name='news_keywords' content={keywords} />
                <meta property='og:url' content={url} />
                <meta property='og:type' content={type} />
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='og:image' content={image} />
                <meta property='keywords' content={keywords} />
                <meta name='twitter:card' content={card} />
                <meta property='twitter:domain' content={domain} />
                <meta property='twitter:url' content={url} />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} />
                <meta name='twitter:image' content={image} />
            </Head>
        </>
    );
}
