export default {
  home: {
    title: 'EYEFIRE',
    description:
      'パイオニアの製品、顔認識技術に基づくソリューション、物体認識、ビデオ、画像の動作を認証です。',
    integrated_title1: 'On Premise',
    integrated_title2: 'Cloud Service',
    integrated_title3: '結合',
    integrated_content1:
      'パートナーと顧客のインフラでオールインワン展開します。',
    integrated_content2:
      'EYEFIREのクラウドサーバーインフラを利用し、迅速に展開します。',
    integrated_content3:
      'MobileSDK, ServerSDK, APIs ほとんどの統合ニーズに対応します。',
    hotTech: '目立つ技術',
    solutions: 'ソリューション',
    solutions_des:
      '包括的なソリューションで、実際の問題を解決する。</br> あらゆるニーズにカスタマイズして対応出来ます。',
    product: '製品',
    product_sub:
      'EyeFire テクノロジーを導入した製品、多くの優れた機能があります。',
    facesdk:
      "<p>EYEIRE SDK を使用して、<br className='item-pc' /> 顔認証技術を既存のアプリケーションやビジネス システムに簡単に統合できます。</p>",
    news: 'ニュース',
    new_des:
      'EYEFIREの技術、製品の情報。イベント、コラボレーション、お知らせなど',

    field: '各界',
    field_sub: 'EYEFIREの技術はあらゆる各界に応用可能',
    customer: '顧客とパートナー',
    customer_des: 'EYEFIREは大手な企業や組織のパートナーで誇りを持っています。',
    start: '統合と展開',
    start_des:
      'マルチプラットフォーム認証技術、ニーズに応じたカスタマイズ、柔軟な展開形態',
    contact: 'コンサルタント用のお問い合わせ',

    prize_title: '<h1>メディアがアイファイアについて語る</h1>',
  },
  layout: {
    vn: 'VN',
    el: 'EN',
    jp: 'JP',
    home: 'ホーム',
    technology: 'テクノロジー',
    product: '製品',
    solution: 'ソリューション',
    sdk: 'SDK',
    job: '採用',
    news: 'ニュース',
    contact: 'お問い合わせ',
    slogan:
      'パイオニアの製品、顔認識技術に基づくソリューション、物体認識、ビデオ、画像の動作を認証です。',
    office: 'オフィス',
    menu: 'メニュー',
    adrHn: '本社',
    adrHcm: 'ホーチミン',
    adrJp: '日本',
    address1:
      '3rd floor, Fafim A Building, 19 Nguyen Trai Street <br/> Thanh Xuan District, Ha Noi City, Viet Nam',
    address2:
      '2F, H&H Building 102 Nguyen Dinh Chinh Street, Ward 15<br/> Phu Nhuan District, Ho Chi Minh City, Viet Nam',
    address3: '〒190-0023 <br />東京都立川市柴崎町3-8-5立川NXビル5F',
    rule1: '利用規約',
    rule2: 'プライバシーポリシー',
    keep: 'CONNECT WITH US',
    copyright: 'Copyright © 2023EYEFIRE Corporation. All rights reserved.',
    contact_footer: 'お問い合わせ',
    content_contact_footer1:
      'ご質問、ご提案、またはパートナーシップの要望をお送りください。',
    content_contact_footer2: '早めに返事致します!',
    button_contact: 'パートナーシップの要望',
    button_detail: '詳細',
    btn_send: '情報送信',
    title_contact: '連絡の情報',
    content_contact1:
      'アドバイス、見積もり、またはビジネスの連絡は情報を送信してください。<br /> いつでもメールでお問い合わせください。',
    content_contact2: 'いつでもヘアリングして、サポート致します。',
  },
  sdk: {
    title_ft1: 'Face Recognition',
    title_ft2: 'Liveness',
    title_ft3: 'Mask Detection',
    content_ft1: '顔認証',
    content_ft2: '絶対的な偽造防止',
    content_ft3: 'マスク検出',
    slogan_sdk: 'FACE RECOGNITION SOFTWARE DEVELOPMENT KIT',
    title_sdk: 'EYEFIRE FACESDK',
    content_sdk1:
      '顧客のところにEYEFIRE の優れた顔認識技術をビジネスサービスに導入',
    button_try: '今すぐ体験',
    title_big_box: 'EYEFIRE FaceSDK',
    content_big_box:
      'iOS、Androidサーバー上で動作するモバイルアプリケーションやバックエンドシステムと簡単に統合できるクロスプラットフォームです。',
    sdk1: 'iOS SDK',
    sdk2: 'Android SDK',
    sdk3: 'Server SDK',
    feature_title:
      'これまでになく簡単にテクノロジーをビジネスのサービスに導入することができます。',
    face_title: '顔認証技術',
    face_content: 'EYEFIRE が開発、習得され事を実際にテスト、実装しました。',
    feature_sdk_title: 'SDKの優れた機能',
    feature_sdk_content:
      'EYEFIRE が開発、習得され事を実際にテスト、実装しました。',
    title_box1: '精度',
    title_box2: '高速',
    title_box3: '1つだけのサンプル画像',
    title_box4: '安全性とセキュリティ',
    title_box5: '拡張の柔軟性',
    content_box1:
      '通常の動作条件で最大<span className="red-text">99.9%</span>、眼鏡や帽子をかぶっていても認証可能です。',
    content_box2:
      '<span className="red-text">1秒</span>未満の認証速度。技術的には、平均認証速度は 20 ～ 30,000 人のユーザーのデータで<span className="red-text">200～300ミリ秒</span>で認証できます。',
    content_box3:
      'トレーニング データとしてサンプル画像を<span className="red-text">1つだけ</span>利用することで、トレーニング エンジンはそれを処理して、後ほどの認証の精度と速度の要件を確保できます。',
    content_box4: '写真、ビデオ、またはその他の形式による正確な認証、偽造防止',
    content_box5:
      'EYEFIRE の顔認証技術はクロスプラットフォームの技術であり、ビジネス ニーズに応じて簡単に同期およびカスタマイズできます。',
    tec_box_1: '迅速な統合',
    tec_box_2: '高性能',
    tec_box_3: '最新のテクノロジー',
    tec_box_4: 'コンパクト',
    tec_content_box1:
      '直感的に設計されたアーキテクチャと機能であり、開発チームがすばやく理解できるように明確なドキュメントがあります。',
    tec_content_box2:
      'EYEFIRE の FaceSDK は、ほとんどのデバイスで認証速度と精度を保証する高性能の SDK です。',
    tec_content_box3:
      'SDK は常に EYEFIRE の開発チームによって最新のテクノロジーでアップグレードされます。',
    tec_content_box4:
      'SDK の容量と A.I モデルは小さく、パッケージ化されており、保管しやすく、モバイル デバイスに適しています。',
  },
  tecnology: {
    hotTech: '目立つ技術',
    description:
      'パイオニアの製品、顔認識技術に基づくソリューション、物体認識、ビデオ、画像の動作を認証です。 ',
  },
  product: {
    product: '製品',
    product_des: 'EyeFire テクノロジーを導入した製品、多くの優れた機能がある',
  },
  news: {
    news: 'ニュース',
    new_des:
      '顔認識技術、動画・画像の物体認識をベースとした製品・ソリューションのパイオニア',
    new_hot: 'ホットニュース',
    new_hostlist: 'EyefireMの最近の注目すべきニュースを見てみましょう',
    list_hot: 'ニュース一覧',
    search: '検索',
    description:
      'EYEFIRE - パイオニアの製品、顔認識技術に基づくソリューション、物体認識、ビデオ、画像の動作を認証です。',
    relate: '関連ニュース',
  },
  solution: {
    title_solution: 'ソリューション',
    content_solution:
      '<p>包括的なソリューションで、実際の問題を解決する。 あらゆるニーズにカスタマイズして対応出来る。<p/>',
  },
  text_button: {
    read_more: 'もっと見る',
    detail: '詳細',
    more: 'もっと検討',
    back: '戻る',
  },
  contact: {
    form_mail: 'メールアドレス',
    form_name: '氏名',
    form_phone: 'メールアドレス',
    form_subject: '電話番号',
    form_purpose: '連絡の目的',
    form_quotation: 'リクルート',
    form_request: '見積もり',
    form_cooperation: 'カウンセリングを依頼する',
    form_recruitment: '協力する',
    form_orther: '他の',
    form_message: 'お問い合わせ内容',
    form_file: '添付ファイル',
  },
  error: {
    title: '見つかりません',
    button: '戻る',
  },
  seo: {
    home: {
      title: 'Trang chủ',
      description:
        'EYEFIRE - Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
    },
    technology: {
      title: 'Công nghệ',
      description:
        'Công nghệ nhận diện khuôn mặt, nhận diện phương tiện giao thông, nhận diện hành vi bất thường, phát hiện sản phẩm lỗi, phát hiện chuyển động, phát hiện xâm nhập, hàng rào ảo, AIoT camera',
    },
    solution: {
      title: 'Giải pháp',
      description:
        'Giải pháp toàn diện, có thể tuỳ biến đáp ứng mọi nhu cầu của khách hàng bao gồm Giải pháp trường học thông minh, Giải pháp toà nhà thông minh, Giải pháp AIoT camera biến camera thường thành AI camera, Giải pháp tự động hoá dây chuyền sản xuất',
    },
    product: {
      title: 'Sản phẩm',
      description:
        'Thiết bị nhận diện chuyên dụng được EYEFIRE phát triển, tích hợp chức năng nhận diện khuôn mặt, nhận diện thẻ quẹt, kết nối với hệ thống mở cửa tự động, hệ thống cảnh báo an ninh giám sát',
    },
    news: {
      title: 'Tin tức',
      description:
        'Những tin tức, sự kiện nổi bật về hoạt động hợp tác kinh doanh, cũng như các hoạt động nội bộ của EYEFIRE',
    },
    sdk: {
      title: 'SDK',
      description:
        'Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng, các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK đa nền tảng bao gồm iOS SDK, Android SDK, Server SDK',
    },
    contact: {
      title: 'Liên hệ',
      description:
        'Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh. Vui lòng gửi email cho chúng tôi bất cứ lúc nào. Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.',
    },
  },
  banner: {
    solution: 'ソリューション',
    slide3: {
      title: '工場の安全',
      desc:
        '不良品検出、スマート勤怠管理、アクセス管理、スマート駐車場、AIoTカメラソリューション (セキュリティ監視、不正侵入警告、危険区域への侵入警告、労働災害警告、従業員活動監視)',
      img_desktop: 'factory.svg',
      img_mb: 'bannerMobile1.png',
    },
    slide1: {
      title: 'スマートスクール',
      desc:
        '学校のすべての日常活動を最適化し、学校と保護者の間の円滑な連絡チャンネルを作成します。',
      img_desktop: 'img_banner_pc.png',
      img_mb: 'bannerMobile2.png',
    },
    slide2: {
      title: 'AIOT Camera',
      desc:
        '普通のカメラを AI カメラに変えるソリューション、一元化されたカメラを管理と保存する、人工知能技術に基づく画像の見ることと分析を可能にするクラウド プラットフォーム',
      img_desktop: 'solution.svg',
      img_mb: 'bannerMobile3.png',
    },
  },
  term: '利用規約',
  policy: 'プライバシーポリシー',
  office: '本社',
  contact_now: 'LIÊN HỆ NGAY',
};
